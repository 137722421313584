(function ($, window, document, undefined) {

    'use strict';


    window.trevett = {

        name: 'T R E V E T T    C R I S T O    L A W',

        MOBILE: false,

        settings: {
            mobile_breakpoint: 768
        },

        modules: {},  // public object we populate

        init: function() {
            //console.log(this.name);

            // initialize foundation
            // ----------------------------------------
            if ($('#node-4').length || $('.page-attorneys-view-page').length) {
                $(document).foundation({
                    dropdown: {
                        is_hover: true,
                        hover_timeout: 250,
                        align: 'top'
                    }
                });

                // do some things for the Attorneys.
                // if they have more than 3 things in their specialties
                $('.f-dropdown.content').each(function (i, val) {
                    var $content = $(val);

                    if (i > 0 && i % 3 === 2) {
                        $content.addClass('iii');
                    }

                    // handle the columns thing if they have more than 3 items
                    if ($content.find('li').length > 3) {
                        $content.addClass('xl');
                        $content.find('ul').addClass('columns');
                    }
                });
            } else {
                $(document).foundation();
            }



            // cache some values
            // ----------------------------------------
            this.WIDTH = window.innerWidth;  // save window width to trevett {}

            if (this.WIDTH < this.settings.mobile_breakpoint) {
                this.MOBILE = true;  // update MOBILE boolean
            }

            this.$container = $('.container');



            // turn on our custom modules
            // ----------------------------------------
            for (var module in this.modules) {
                this.modules[module].init();
            }



            // do some things
            this.resize();
            var resize = this.resize;
            $(window).on('resize', Foundation.utils.throttle(function(e){
                resize();
            }, 300));


            // hack the forms with JS because drupal
            // search form in side nav
            $('#edit-search-block-form--2').attr('placeholder', 'Search');
            // search in footer
            $('#views-exposed-form-looking-search-page-1 .form-text').attr('placeholder', 'Search');


            // FEATURED VIDEO PAGES
            var feat_video = $('#main').find('#featured_video');
            // check if page is a featured service page to manipulate vimeo player
            if (feat_video.length) {


                var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
                console.log(path);
                if (window.location.search.indexOf('transcript_open') > -1) {
                    console.log('parameter is present');
                    $('.field-expander').find('.read-more a').triggerHandler('click');
                } else {
                    console.log('parameter is not met');
                }

                $('#transcript-lk').on('click', function (event) {
                    event.preventDefault();
                    console.log('toggle worked');

                    $('.field-expander').find('.read-more a').triggerHandler('click');
                });

                var test1 = $('.field-expander').find('.details');
                if (test1.length) {
                    console.log('details exists');
                }
            }


            var mqx = window.matchMedia( '(min-width: 770px)' );
            var iframe = document.querySelector('iframe');
            var player = new Vimeo.Player(iframe);
            var feat_serv_page = $('#main').find('#featured_service_page');
            var playButton = document.getElementById("video_play");
            var intro = $('.feat-service-wrap').find('#feat-intro');
            var play_but = $('.feat-service-wrap').find('.video-play-wrap');
            var playing_vid = $('.feat-service-wrap').find('.video-wrap');
            var video_wrap = $('.feat-service-wrap').find('#video2');
            var prefix_img = $('.feat-service-wrap').find('.image');
            if (mqx.matches) {
                // console.log(mqx);

                // FEATURED SERVICE PAGE

                // check if page is a featured service page to manipulate vimeo player
                if (feat_serv_page.length) {
                    // vimeo play
                    console.log('this is a service page');



                    player.on('play', function() {
                        console.log('played the video!');

                    });

                    player.getVideoTitle().then(function(title) {
                        console.log('title:', title);
                    });

                    // player.on('ready', function() {
                    // console.log('play is ready to play');





                    $('#video_play').on('click', function (event) {
                        console.log('featured intro was clicked');
                        video_wrap.show();
                        prefix_img.hide();
                        player.play();
                        intro.addClass('hideplay');
                        play_but.addClass('played_but');
                        playing_vid.addClass('playing_vid');

                    });


                    // player.on('pause', function() {
                    //     console.log('video pausing right now!!!');
                    // });

                    player.on('finish', function() {
                        console.log('video has ended');
                        play_but.toggleClass('played_but');
                        intro.toggleClass('hideplay');
                        video_wrap.hide();
                        prefix_img.show();
                    });
                }
            } else {
                console.log('smaller than mqx');
                player.on('finish', function() {
                        console.log('video has ended in mobile');
                        play_but.toggleClass('played_but');
                        intro.toggleClass('hideplay');
                        video_wrap.hide();
                        prefix_img.show();
                });
            }
            // end of featured service check


            // ------------------------
            // Social Sharing api's
            var fb_share = $('.feat-social-list').find('#fb-share');
            var share_path = window.location.href;
            fb_share.on('click', function (event) {
                console.log('fb_share was clicked');
                FB.ui({
                  method: 'share_open_graph',
                  action_type: 'og.likes',
                  action_properties: JSON.stringify({
                    object:share_path,
                  })
                }, function(response){
                  // Debug response (optional)
                  // console.log(response);
                });
            });

        },

        resize: function () {

            var $container = $('.container');
            if ($container.height() < window.innerHeight) $container.css('height', '100%');

            // magellan fixes
            // ----------------------------------------
            // when the magellan nav is active it has a pos:fixed, which means it takes
            // up 100% of the window width. we added a { left: auto !important; } to the
            // magellan container, which seems to place it where we want it, but any
            // max-width rules never seem to apply. so we set a max-width to the .sub-nav
            // of every magellan nav we have.
            //
            // all magellan navs should have have a class of '.magellan-container'
            $('.magellan-container').each(function (i, el) {
              // set the max-width of .sub-nav to pre-"pos:fixed" width
              var $el = $(el).find('.sub-nav');  // cache the DOM ref to $el
              $el.css('max-width', $el.width()+'px');
            });


            // make sure all the <li>s are the same height
            $('.news-and-updates-list').each(function (i, ul) {
                var $list = $(ul);
                var max_height = 0;

                // loop twice.
                // 1 - find the max height
                $list.children().each(function (i2, li) {
                    var li_height = $(li).height();
                    if (li_height > max_height) {
                        max_height = li_height;
                    }
                });
                // 2 - apply max_height to all <li>s
                $list.children().each(function (i2, li) {
                    $(li).css('height', max_height);
                });
            });
        }
    };


    $(document).ready(function () {
        trevett.init();

    });

})(jQuery, this, this.document);
