(function($, window, document, undefined) {
    'use strict';
    /**
     *  expects this kind of HTML:
         nav#nav.nav
            div#nav-toggle.nav-toggle
              span Menu
            ul#nav-list.nav-list
              li
                a(href="#") ~/
     */
    trevett.modules.nav = {
        ACTIVE: false, // boolean for mobile, when we toggle the nav
        ACTIVE_CLASSNAME: 'active', // the className we apply to the DOM nodes when toggled
        LEVEL: 1, // level refers to the nav level. top level = 1, dropdowns = 2, etc.
        LEVEL_CLASSNAME: 'level-', // level-1, level-2, etc will be added to the nav list
        SETTINGS: {}, // will hold our initial settings
        init: function() {
            // save our settings
            this.SETTINGS = {
                nav: '#nav',
                toggle: '#nav-toggle',
                list: '#main-menu',
                header: '#header-container',
                content: '#content-container'
            };
            // our DOM references
            this.$body = $(document.body);
            this.nav = document.querySelector(this.SETTINGS.nav);
            this.toggle = document.querySelector(this.SETTINGS.toggle);
            this.list = document.querySelector(this.SETTINGS.list);
            this.header = document.querySelector(this.SETTINGS.header);
            this.content = document.querySelector(this.SETTINGS.content);
            this.nested_list = undefined; // will be updated as user navigates
            this.$phone_number = $('#header-container .phone-number');
            var oldIE;
            if ($('html').is('.ie6, .ie7, .ie8, .ie9')) {
                oldIE = true;
            }
            // - add Back buttons, because top bar doesn't wanna play nice
            var $dropdowns = $('.has-dropdown > a');
            $.each($dropdowns, function(key, value) {
                // some HTML to add to our Menu
                var $current_link = $(value),
                    back_html = '<li class="parent-link"> <a href="' + $current_link.attr('href') + '">' + $current_link.html() + '</a> </li>',
                    parent_link = '<li class="nav-back-btn"> <a href="#">Close</a> </li>';
                // add the Parent item again
                $current_link.next('.dropdown').prepend(back_html + parent_link);
            });
            // add classes to handle the nav icons
            // ----------------------------------------
            var classes_to_add_to_menu_options = [{
                'Commercial Services': 'commercial-services-menu-icon'
            }, {
                'Insurance Defense': 'insurance-defense-menu-icon'
            }, {
                'Labor & Employment': 'labor-matters-menu-icon'
            }, {
                'Litigation': 'litigation-menu-icon'
            }, {
                'Personal Services': 'personal-services-menu-icon'
            }, {
                'News & Updates': 'news-updates-menu-icon'
            }, {
                'Industry Articles': 'industry-menu-icon'
            }, {
                'Published Articles': 'published-works-icon'
            }, {
                'Case Studies': 'case-studies-menu-icon'
            }, {
                'History': 'history-menu-icon'
            }];
            $('#main-menu li a').each(function(i, ele) {
                for (var j = 0; j < classes_to_add_to_menu_options.length; j++) {
                    if (classes_to_add_to_menu_options[j][ele.textContent]) {
                        $(ele).prepend('<i class="' + classes_to_add_to_menu_options[j][ele.textContent] + '"></i>');
                    }
                }
            });
            // setup onclick for #nav-toggle
            // ----------------------------------------
            this.toggle.onclick = function() {
                if (trevett.modules.nav.ACTIVE) trevett.modules.nav.ACTIVE = false;
                else trevett.modules.nav.ACTIVE = true;
                trevett.modules.nav.update();
            };
            // setup dropdown nav links
            this.list.onclick = function(event) {
                // check if there is a .nested-nav-link, which should be the sibling
                // else go back a level
                var self = trevett.modules.nav,
                    target = event.target.nextElementSibling,
                    goUpALevel = false,
                    update = false;
                //Variables to check for IE 9
                var ua = window.navigator.userAgent;
                var msie = ua.indexOf('MSIE');
                if (target && target.className === 'dropdown') {
                    //If IE 9, then ignore preventDefault and don't pop out nav
                    if (msie > 0) {
                        //testing purposes
                        // document.write( "This is Internet Explorer 9 or earlier" );
                    }
                    //If browser is IE 9 or higher or different type of browser, contiune script
                    else {
                        event.preventDefault();
                        // we are moving deeper into the nav
                        self.LEVEL++;
                        goUpALevel = true;
                        update = true;
                        // prevent default if we are on mobile
                        // on desktop, if the nav is open and they link on the parent link, go to the link
                        // else slide open
                        if (self.MOBILE || !self.ACTIVE) {
                            // check this.nested_list
                            if (self.nested_list !== undefined) {
                                //console.log('we\'re looking at a new option');
                                self.shift(target);
                                return;
                            }
                        }
                    }
                }
                // check if we clicked on the .nav-back-btn
                else if (event.target.className === 'nav-back-btn' || event.target.parentNode.className === 'nav-back-btn') {
                    event.preventDefault();
                    self.LEVEL--;
                    // normalize what we return, sometimes we get the wrong node
                    target = (event.target.className === 'nav-back-btn') ? event.target.parentNode : event.target.parentNode.parentNode;
                    update = true;
                }
                // update the nav
                if (update) self.update(target, goUpALevel);
            };
        },
        shift: function(_new_list) {
            /**
             * this function simply siwtches classes for nested-nav-links
             * for desktop, when the nav is open, just switch between the selected navs
             */
            this.nested_list.className = this.nested_list.className.replace(' ' + this.ACTIVE_CLASSNAME, '');
            // update our reference
            this.nested_list = _new_list;
            this.nested_list.className += ' ' + this.ACTIVE_CLASSNAME;
        },
        update: function(_nested_nav_list, _next) {
            /**
             * this function does all the nav magic. it checks some of the settings
             * attached to this trevett.nav object before it does anything.
             * it adds a className of 'active' to certain objects (this className
             * can be changed by editing the ACTIVE_CLASSNAME value)
             *
             * Checks the values of:
             *     ACTIVE  (boolean)    mostly for mobile, if the nav is open
             *     LEVEL   (int)        for deeper levels on mobile, used in classNames
             *
             * Parameters:
             *     _nested_nav_list  (optional)  our nested nav target
             *     _next  (boolean)     if true, next nevel; if false, go back a level
             *     _new_list            if provided, the _nested_nav_list has the class
             *                          "active" removed, and active is applied to
             *                          the _new_list
             *                          * this is for desktop. when you open the nav
             *                          it should switch the active nested list while
             *                          keeping the nav "active"
             *                          *
             *
             */
            // this first if block happens once the nav is already active
            // show nested nav lists
            if (_nested_nav_list) {
                // if we clicked a option with nested links, we go to the next layer
                if (_next) {
                    // update the current nested_list ref
                    this.nested_list = _nested_nav_list;
                    // add class "active" to the targeted list
                    this.nested_list.className += ' ' + this.ACTIVE_CLASSNAME;
                    // do different things depending on the screen size
                    if (trevett.MOBILE) {
                        // add appropriate className for how deep we are
                        this.list.className += ' ' + this.LEVEL_CLASSNAME + this.LEVEL;
                    }
                    else {
                        // add class "active" to...
                        this.header.className += ' active';
                        this.content.className += ' active';
                        // this.$body.css('position', 'fixed');
                    }
                }
                else {
                    // update the nested nav
                    this.nested_list.className = this.nested_list.className.replace(' ' + this.ACTIVE_CLASSNAME, '');
                    this.nested_list = undefined; // reset this ref
                    // update the whole list
                    this.list.className = this.list.className.replace(' ' + this.LEVEL_CLASSNAME + (this.LEVEL + 1), '');
                    if (!trevett.MOBILE) {
                        this.header.className = this.header.className.replace(' ' + this.ACTIVE_CLASSNAME, '');
                        this.content.className = this.content.className.replace(' ' + this.ACTIVE_CLASSNAME, '');
                        // this.$body.removeAttr('style');
                    }
                }
            }
            else {
                // this block is for mobile, toggling the menu
                if (this.ACTIVE) { // turn on the nav
                    this.toggle.className += ' ' + this.ACTIVE_CLASSNAME;
                    this.list.className += ' ' + this.ACTIVE_CLASSNAME;
                    this.$phone_number.addClass(this.ACTIVE_CLASSNAME);
                }
                else { // turn off the nav
                    this.toggle.className = this.toggle.className.replace(' ' + this.ACTIVE_CLASSNAME, '');
                    this.list.className = this.list.className.replace(' ' + this.ACTIVE_CLASSNAME, '');
                    this.$phone_number.removeClass(this.ACTIVE_CLASSNAME);
                    // remove the level-# class
                    if (this.LEVEL > 1) {
                        // we were a few levels deep, but now we want to just close out the nav
                        // remove the current level class, ex level-2
                        this.list.className = this.list.className.replace(' ' + this.LEVEL_CLASSNAME + this.LEVEL, '');
                        this.nested_list.className = this.nested_list.className.replace(' ' + this.ACTIVE_CLASSNAME, '');
                        this.LEVEL = 1; // reset the level
                    }
                }
            }
        }
    };
}(jQuery, window, document));
