(function ($, window, document, undefined) {
    'use strict';


    trevett.modules.kitchen_sink = {
        init: function () {

            if ($('#kitchen-sink').length) {
              // find all the <button>s in <pre> tags
              //- var $copy_buttons = $('pre button');
              var $copy_buttons = $('.copy-btn');

              $.each($copy_buttons, function (i, element) {
                  // set the data attribute to the text value in <code> sibling
                  var $btn = $(element);
                  $btn.attr('data-clipboard-text', $.trim($btn.siblings('.hidden').html()));
              });



              // set the clipboard client
              var client = new ZeroClipboard($copy_buttons);

              client.on( "ready", function( readyEvent ) {

                  client.on('click', function (event) {
                      event.preventDefault();
                  });
                  client.on( "aftercopy", function( event ) {
                      // event.preventDefault();
                      // `this` === `client`
                      // `event.target` === the element that was clicked
                      //- event.target.style.display = "none";
                      //console.log("Copied text to clipboard: " + event.data["text/plain"]);
                  } );
              } );

            }

        }
    }


}(jQuery, window, document));
